import React from "react";
import ImageSliderHome from "../../components/imageslider";
import HomeIntro from "../../components/intro";
import Certificate from "../../components/certificate";
import ijw1 from "../sample/sw1.jpeg";
import ijw2 from "../sample/sw2.jpeg";
import ijw3 from "../sample/sw3.jpeg";
import "./home.css";
function Home() {
  return (
    <div style={{ backgroundImage: "linear-gradient(#0100ff,#00ffa6)" }}>
      <ImageSliderHome />
      <HomeIntro />
      <div style={{ textAlign: "center" }}>
        <div
          class="card rei"
          style={{
            margin: "10px",
            marginBottom: "0px",
            display: "inline-block",
          }}
        >
          <img class="card-img-top" src={ijw1} alt="Card image cap" />
          <div class="card-body">
            <p className="card-text">
              <a
                href="/"
                onClick={() => {
                  window.open(
                    "https://youtube.com/channel/UCjwxJyUubn5kve0RUQx-rbw"
                  );
                }}
              >
                Youtube
              </a>
            </p>
            <p className="card-text">
              <a
                href="/"
                onClick={() => {
                  window.open(
                    "https://www.instagram.com/zindagiwithmindfulness?r=nametag"
                  );
                }}
              >
                Instagram
              </a>
            </p>
            <p className="card-text">
              <a
                href=""
                onClick={() => {
                  window.open("https://www.facebook.com/ashubhateja1101/");
                }}
              >
                FaceBook
              </a>
            </p>
            <p className="card-text"></p>
          </div>
        </div>
        <div
          class="card rei"
          style={{
            margin: "10px",
            marginBottom: "0px",
            display: "inline-block",
          }}
        >
          <img class="card-img-top" src={ijw2} alt="Card image cap" />
          <div class="card-body">
            <p class="card-text">
              REIKI GRANDMASTER RAJEEB LOCHAN PANDA Founder of{" "}
              <a
                href="/"
                onClick={() => {
                  window.open("https://www.infiniteloveacademy.com/");
                }}
              >
                INFINITE LOVE ACADEMY{" "}
              </a>{" "}
              .<br /> Enriching and transforming lives through REIKI.
            </p>
          </div>
        </div>
        <div
          class="card rei"
          style={{
            margin: "10px",
            marginBottom: "0px",
            display: "inline-block",
          }}
        >
          <img class="card-img-top" src={ijw3} alt="Card image cap" />
          <div class="card-body">
            <p class="card-text">Advance Healer @Infinite Love Academy DELHI</p>
          </div>
        </div>
      </div>
      <div
        class="card"
        style={{
          width: "100%",
          textAlign: "center",
          color: "white",
          fontWeight: "bold",
          backgroundColor: "unset",
          fontSize: "large",
          marginTop: "5px",
        }}
      >
        <p class="card-text">
          Healthwithmindfulness | Zindagiwithmindfulness | AYuvi Health | AYuvi
          Health Essentials | Reiki Healing Session
        </p>
      </div>
    </div>
  );
}

export default Home;
