import React,{useEffect,useState} from 'react'
import { Link } from 'react-router-dom';
import mainimage from "./main.jpg"
function Navigation() {
    return (
        <nav className="navbar navbar-expand-lg navbar-light " style={{backgroundColor:"rgb(41, 67, 100)",borderBottom:"4px solid rgb(144,255,144)"}}>
        <a class="navbar-brand" style={{color:"white",fontFamily:"Trebuchet MS, Lucida Sans Unicode, Lucida Grande, Lucida Sans, Arial, sans-serif",fontWeight:"bold"}}>
        <img src={mainimage} width="30" height="30" class="d-inline-block align-top mr-1" alt=""/>
        AYuvi Health</a>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" style={{backgroundColor:"white"}}>
                <span className="navbar-toggler-icon" ></span>
            </button>

            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav mr-auto">
                <li className="nav-item active">
                    <Link style={{color:"white",fontFamily:"Trebuchet MS, Lucida Sans Unicode, Lucida Grande, Lucida Sans, Arial, sans-serif",fontWeight:"bold"}} className="nav-link" to="/">Home<span className="sr-only">(current)</span></Link>
                </li>
                <li className="nav-item dropdown">
                    <a style={{color:"white",fontFamily:"Trebuchet MS, Lucida Sans Unicode, Lucida Grande, Lucida Sans, Arial, sans-serif",fontWeight:"bold"}} className="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    Program
                    </a>
                    <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                    <Link className="dropdown-item" to="/programs">Our Programs</Link>
                    <Link className="dropdown-item" to="/events">Upcoming Events</Link>
                    </div>
                    
                </li>
                <li className="nav-item dropdown">
                    <a style={{color:"white",fontFamily:"Trebuchet MS, Lucida Sans Unicode, Lucida Grande, Lucida Sans, Arial, sans-serif",fontWeight:"bold"}} className="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    Essentials
                    </a>
                    <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                    <Link className="dropdown-item" to="/product">Health / Healing Essential</Link>
                    <Link className="dropdown-item" to="/order">Place Order</Link>
                    </div>
                </li>
                <li className="nav-item active">
                    <Link style={{color:"white",fontFamily:"Trebuchet MS, Lucida Sans Unicode, Lucida Grande, Lucida Sans, Arial, sans-serif",fontWeight:"bold"}} className="nav-link" to="/review">Testimonial</Link>
                </li>
                <li className="nav-item active">
                    <Link style={{color:"white",fontFamily:"Trebuchet MS, Lucida Sans Unicode, Lucida Grande, Lucida Sans, Arial, sans-serif",fontWeight:"bold"}} className="nav-link" to="/contact">Contact Us</Link>
                </li>
                </ul>
            </div>
        </nav>
    )
}

export default Navigation;
