import React, { useEffect, useState } from "react";
import readPFireStore from './firebase/firestore/proread';
import EntryId from "./firebase/idgen";
import WritePropost from "./firebase/firestore/writepropost";
import WriteOrderData from "./firebase/database/writepro";
function Order() {
    const [reviews, setReviews] = useState([]);
    useEffect(() => {
        const myFunction = async () => {
        const reviews = await readPFireStore();
            setReviews(reviews);
        };
        myFunction();
    }, []);
    return (
        <div style={{backgroundImage:"linear-gradient(#0100ff,#00ffa6)"}}>
            <p class="h6" style={{color:"white",padding:"10px",textAlign:"center",backgroundColor:"#3e00b0"}}>Place Your Order</p>
            <div class="form-group">
                <label htmlFor="namef" style={{color:"white",margin:"5px" ,fontWeight:"bold",fontFamily:"Trebuchet MS, Lucida Sans Unicode, Lucida Grande, Lucida Sans, Arial, sans-serif"}}>Enter Your Name</label>
                <input type="text" class="form-control"style={{width:"95%",margin:"5px"}} id="namef" placeholder="Enter Your Name"/>
            </div>
            <div class="form-group">
                <label htmlFor="age" style={{color:"white" ,margin:"5px",fontWeight:"bold",fontFamily:"Trebuchet MS, Lucida Sans Unicode, Lucida Grande, Lucida Sans, Arial, sans-serif"}}>Enter Your Age</label>
                <input type="number" class="form-control"style={{width:"95%",margin:"5px"}} id="age" placeholder="Enter Your Age"/>
            </div>
            <div class="form-group">
                <label htmlFor="email" style={{color:"white" ,margin:"5px",fontWeight:"bold",fontFamily:"Trebuchet MS, Lucida Sans Unicode, Lucida Grande, Lucida Sans, Arial, sans-serif"}}>Email address</label>
                <input type="email" class="form-control" id="email" aria-describedby="emailHelp" style={{width:"95%",margin:"5px"}} placeholder="Enter your email"/>
                <small id="emailHelp" class="form-text" style={{margin:"5px",color:"white" ,fontWeight:"bold",fontFamily:"Trebuchet MS, Lucida Sans Unicode, Lucida Grande, Lucida Sans, Arial, sans-serif"}}>Write N.A if the email ID is not available</small>
            </div>
            <div class="form-group">
                <label htmlFor="number" style={{color:"white" ,margin:"5px",fontWeight:"bold",fontFamily:"Trebuchet MS, Lucida Sans Unicode, Lucida Grande, Lucida Sans, Arial, sans-serif"}}>Enter Your Phone number</label>
                <input type="text" class="form-control"style={{width:"95%",margin:"5px"}} id="number" placeholder="Enter Your Phone number"/>
            </div>
            <div class="form-group">
                <label htmlFor="city" style={{color:"white" ,margin:"5px",fontWeight:"bold",fontFamily:"Trebuchet MS, Lucida Sans Unicode, Lucida Grande, Lucida Sans, Arial, sans-serif"}}>Enter City Name</label>
                <input type="text" class="form-control"style={{width:"95%",margin:"5px"}} id="city" placeholder="Enter Your City Name"/>
            </div>
            <div class="form-group">
                <label htmlFor="addr" style={{color:"white",margin:"5px" ,fontWeight:"bold",fontFamily:"Trebuchet MS, Lucida Sans Unicode, Lucida Grande, Lucida Sans, Arial, sans-serif"}}>Enter your Address</label>
                <input type="text" class="form-control"style={{width:"95%",margin:"5px"}} id="addr" placeholder="Enter Your Address"/>
            </div>
            <div class="form-group">
                <label htmlFor="itmestack" style={{color:"white",margin:"5px" ,fontWeight:"bold",fontFamily:"Trebuchet MS, Lucida Sans Unicode, Lucida Grande, Lucida Sans, Arial, sans-serif"}}>Select Item</label>
                <select type="text" class="form-control"style={{width:"95%",margin:"5px"}} id="itemstack" placeholder="Select Your Item">
                <option value="" disabled selected>Select Item</option>
                    {reviews.length > 0 &&
                    reviews.map(
                        (review) =>
                        !(review.name.trim() === "" || review.url.trim() === "" || review.id.trim() === "" || review.price.trim() === "" || review.data.trim() === "") && (
                            <option value={"Product name : "+review.name+" Id : "+review.id}>{"Product name : "+review.name+" Id : "+review.id}</option>
                        )
                    )}
                </select>
            </div>
            <div class="form-group">
                <label htmlFor="itemp" style={{color:"white",margin:"5px" ,fontWeight:"bold",fontFamily:"Trebuchet MS, Lucida Sans Unicode, Lucida Grande, Lucida Sans, Arial, sans-serif"}}>Enter Quantity</label>
                <input type="number" class="form-control"style={{width:"95%",margin:"5px"}} id="itemp" placeholder="Enter Your Quantity"/>
            </div>
            <div className="form-group" >
                <label htmlFor="lists" style={{color:"white",margin:"5px" ,fontWeight:"bold",fontFamily:"Trebuchet MS, Lucida Sans Unicode, Lucida Grande, Lucida Sans, Arial, sans-serif"}}>Your Selected Items List</label>
                <textarea class="form-control" id="lists" disabled={true} style={{resize:"none",height:"200px",width:"95%",margin:"5px"}}></textarea>
            </div>
            <div className="form-group">
                <p class="h6" id="orderst" style={{color:"white",textAlign:"center"}}></p>
            </div>
            <div style={{textAlign:"center"}}>
                <p class="h6" id="orderst" style={{color:"red",textAlign:"center"}}>delivery charges will be applicable *</p>
            </div>
            <div style={{textAlign:"center"}}>
                <button class="btn btn-success" onClick={() => {
                    var item = document.getElementById("itemstack").value;
                    var itemnum = document.getElementById("itemp").value;
                    if(item == "Select Item" || itemnum == 0){
                        alert("select value");
                    }else{
                        document.getElementById("lists").innerHTML += item +" No of Quantity : "+ itemnum.toString()+"\n\n";
                    }
                }} style={{margin:"5px"}}>Add Item</button>
                <button class="btn btn-success" onClick={() => {
                    document.getElementById("lists").innerHTML = "";
                }} style={{margin:"5px"}}>Delete All Item</button>
            </div>
            <div style={{textAlign:"center"}}>
                <button type="submit" onClick={() => { 
                    var e1 = document.getElementById("email").value;
                    var e2 = document.getElementById("number").value;
                    var e3 = document.getElementById("city").value;
                    var e4 = document.getElementById("addr").value;
                    var e5 = document.getElementById("lists").value;
                    var e6 = document.getElementById("namef").value;
                    var e7 = document.getElementById("age").value;
                    if(e1 == "" || e2 == "" || e3 == "" || e4 =="" || e6 == "" || e7 ==""){
                        alert("Please fill correct email/number/city/address");
                    }else{
                        if(e5 == ""){
                            alert("Please fill the your item list");
                        }else{
                            var d = new Date();
                            var orderid = EntryId().toString();
                            var dates = d.toString();
                            WriteOrderData(orderid,e6,e7,e1,e2,e3,e4,e5,dates);
                            WritePropost(orderid,e6,e7,e1,e2,e3,e4,e5,dates);
                            document.getElementById("orderst").innerHTML = "ORDER ID : "+orderid;
                            alert("Your Order ID : "+orderid+"\nwe will call you for verification\nThank you\nAyuvi");
                            document.getElementById("email").value = "";
                            document.getElementById("number").value = "";
                            document.getElementById("city").value = "";
                            document.getElementById("addr").value = "";
                            document.getElementById("lists").value = "";
                            document.getElementById("namef").value = "";
                            document.getElementById("age").value = "";
                        }
                    }
                }} class="btn btn-primary">Place Order</button>
            </div>
        </div>
    )
}

export default Order;
