import { getFirestore, collection, getDocs } from "firebase/firestore";
import app from "../firebase";

const readPFireStore = async () => {
  const db = getFirestore(app);
  const querySnapshot = await getDocs(collection(db, "postproduct"));
  const reviews = [];
  
  querySnapshot.forEach((doc) => {
    reviews.push(doc.data());
  });
  if (reviews === []) {
    return { error: "NoProducts" };
  } else {
    return reviews;
  }
};

export default readPFireStore;
