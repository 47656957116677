import { getFirestore, collection, getDocs } from "firebase/firestore";
import app from "../firebase";

const readEvents = async () => {
  const db = getFirestore(app);
  const querySnapshot = await getDocs(collection(db, "events"));
  const reviews = [];
  
  querySnapshot.forEach((doc) => {
    reviews.push(doc.data());
  });
  if (reviews === []) {
    return { error: "NoPrograms" };
  } else {
    return reviews.reverse();
  }
};

export default readEvents;
