import React from 'react'
import "./review.css";
import "./c.css"
import WriteCData from "../firebase/database/contactdata";
import WriteCFire from "../firebase/firestore/contactdata";
import EntryId from "../firebase/idgen";
function ConTact() {
    return (
        <div className="lagster" style={{backgroundImage:"linear-gradient(#0100ff,#00ffa6)"}}>
            <div className="sublag connect">
          <p className="rev">Contact Us</p>
          <input
            type="text"
            style={{ marginTop: "2px"  ,width:"90%"}}
            id="cnida"
            className="inp"
            placeholder="Enter name"
          />
          <br />
          <input
            type="text"
            style={{ marginTop: "10px"  ,width:"90%"}}
            id="cnidb"
            className="inp"
            placeholder="Enter number"
          />
          <br />
          <input
            type="text"
            style={{ marginTop: "10px" ,width:"90%"}}
            id="cnidc"
            className="inp"
            placeholder="Enter message"
          />
          <br />
          <button
            className="btnid"
            style={{margin:"4px"}}
            id="conid"
            onClick={() => {
              var c1 = document.getElementById("cnida").value;
              var c2 = document.getElementById("cnidb").value;
              var c3 = document.getElementById("cnidc").value;
              if (c1 == "" || c2 == "" || c3 == "") {
                alert("Fill all the values carefully");
              } else {
                var idse = EntryId().toString();
                WriteCData(idse, c1, c2, c3);
                WriteCFire(idse, c1, c2, c3);
                alert(
                  "We will contact you in upcoming hours!\nThank You\nAyuvi"
                );
                document.getElementById("cnida").value = "";
                document.getElementById("cnidb").value = "";
                document.getElementById("cnidc").value = "";
              }
            }}
          >
            SUBMIT
          </button>
        </div>
            <div style={{width:"90%",margin:"auto",marginTop:"30px" ,display:"inline-block", backgroundColor:"white", textAlign:"center",border:"1px solid gray"}}>
            <div class="card-body">
                <h5 class="card-title">AYuvi Health and Fitness Center</h5>
                <p class="card-text">Address : Rohini,Sector-04,New Delhi - 110085</p>
                <p className="card-text">Customer Care number : 011 7121 7914</p>
                <p className="card-text">Customer Care timings : 9am - 5pm (Monday - Saturday)</p>
                <p className="card-text">You can Email us : </p>
                <p className="card-text jhfg" onClick={() => {
                  navigator.clipboard.writeText("healthwithmindfulness@gmail.com");
                }}>healthwithmindfulness@gmail.com</p>
                <p className="card-text jhfg" onClick={() => {
                  navigator.clipboard.writeText("zindagiwithmindfulness@gmail.com");
                }}>zindagiwithmindfulness@gmail.com</p>
            </div>
            </div>
            <div style={{width:"90%",margin:"auto",marginTop:"30px" ,display:"inline-block", backgroundColor:"white",textAlign:"left"}}>
            <div class="card-body">
                <h5 class="card-title">Notice :</h5>
                <p class="card-text">BEWARE OF SPURIOUS PHONE CALLS AND FICTITIOUS/ FRAUDULENT OFFERS  <br/>• Public receiving such phone calls are requested to lodge a police complaint.
issued in public interest</p>
            </div>
            </div>
        </div>
    )
}

export default ConTact;
