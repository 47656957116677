import React,{useState , useEffect} from 'react'
import readPFireStore from './firebase/firestore/proread';
import { Link } from 'react-router-dom';
function Product() {
    const [reviews, setReviews] = useState([]);
    useEffect(() => {
        const myFunction = async () => {
        const reviews = await readPFireStore();
            setReviews(reviews);
        };
        myFunction();
    }, []);
    return (
        <div style={{textAlign:"center",backgroundImage:"linear-gradient(#0100ff,#00ffa6)"}}>
            {reviews.length > 0 &&
            reviews.map(
                (review) =>
                !(review.name.trim() === "" || review.url.trim() === "" || review.id.trim() === "" || review.price.trim() === "" || review.data.trim() === "") && (
                    <div class="card edit-some-div-card" style={{width: "15rem",margin:"10px",display:"inline-block"}}>
                    <img class="card-img-top edit-some-div-card w-100" style={{height:"200px"}} src={review.url} alt="Card image cap"/>
                    <div class="card-body">
                        <p class="card-text" style={{textAlign:"left"}}>NAME : {review.name}</p>
                        <p class="card-text" style={{textAlign:"left"}}>PRICE : <del>₹{review.aprice}</del>  ₹{review.price}</p>
                        <p class="card-text" style={{textAlign:"left"}}>ID : {review.id}</p>
                        <div class="dropdown">
                        <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{backgroundColor:"#084ef1",color:"white"}}>
                            Description
                        </button>
                        <br/>
                        <Link class="btn btn-secondary bg-success" to="/order" type="button" style={{backgroundColor:"lime",color:"white",marginTop:"6px"}}>
                            Buy
                        </Link>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <div class="list-group">
                                <a class="list-group-item list-group-item-action flex-column align-items-start" style={{border:"0px solid white" ,textAlign:"left"}}>
                                    <p class="mb-1">{review.data}</p>
                                </a>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                )
            )}
        </div>
    )
}

export default Product;
