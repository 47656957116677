import { initializeApp } from "firebase/app";
const firebaseConfig = {
  apiKey: "AIzaSyBeqmkH0nkgQSOYtDWjztqr6QSFwGqSxX4",
  authDomain: "ayuvi-health.firebaseapp.com",
  projectId: "ayuvi-health",
  storageBucket: "ayuvi-health.appspot.com",
  messagingSenderId: "972105005822",
  appId: "1:972105005822:web:919e4ed1001b1c673043af",
  measurementId: "G-9CG2VXD20C"
};
const app = initializeApp(firebaseConfig);
export default app;
