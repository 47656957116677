import app from "../firebase";
import { getDatabase, set, ref } from "firebase/database";
function WriteOrderData(id, nam,ag,emai, phon, cit,addr,listss,dat) {
  const database = getDatabase(app);
  set(ref(database, "incomingOrder/" + id +" "+ dat+ " "+nam), {
    OrderId:id,
    name: nam,
    age:ag,
    email:emai,
    phone:phon,
    city:cit,
    address:addr,
    item:listss,
    date:dat,
  }).catch((error) => {
    console.log("internal error");
  });
}
export default WriteOrderData;
