import React,{useState , useEffect} from 'react'
import readPrograms from '../firebase/firestore/ever';
import EntryId from "../firebase/idgen";
import WriteSession from "../firebase/firestore/sessionadd"
function Session() {
    const [reviews, setReviews] = useState([]);
    useEffect(() => {
        const myFunction = async () => {
        const reviews = await readPrograms();
            setReviews(reviews);
        };
        myFunction();
    }, []);
    return (
        <div style={{}}>
            <div style={{backgroundColor:"#1b1b1b",textAlign:"center",backgroundImage:"linear-gradient(#0100ff,#00ffa6)"}}>
            <p class="h6" style={{color:"white",padding:"10px",textAlign:"center"}}>Book Your First Session </p>
            <div class="form-group">
                <input type="text" class="form-control" style={{width:"90%",textAlign:"center",margin:"auto"}} id="nhuj" placeholder="Enter Your Name"/>
            </div>
            <div class="form-group">
                <input type="text" class="form-control" style={{width:"90%",textAlign:"center",margin:"auto"}} id="nuciu" placeholder="Enter Your City"/>
            </div>
            <div class="form-group">
                <input type="email" class="form-control" style={{width:"90%",textAlign:"center",margin:"auto"}} id="empl" aria-describedby="emailHelp" placeholder="Enter your email"/>
                <small id="emailHelp" class="form-text" style={{color:"white", fontWeight:"bold"}}>Write N.A if the email ID is not available</small>
            </div>
            <div class="form-group">
                <input type="text" class="form-control" style={{width:"90%",textAlign:"center",margin:"auto"}} id="citye" placeholder="Enter Your Phone number"/>
            </div>
            <div class="form-group">
                <select type="text" class="form-control" style={{width:"90%",textAlign:"center",margin:"auto"}} id="progyu" placeholder="Enter Your Phone number">
                <option value="" disabled selected>Select your option</option>
                {reviews.length > 0 &&
            reviews.map(
                (review) =>
                !(review.name.trim() === "" || review.headurl.trim() === "" ||  review.des.trim() === "" || review.url.trim() === "") && (
                    <option value={review.name}>{review.name}</option>
                ))
        }
                <option value="Free trial session">Free trial session</option>
                </select>
            </div>
            <div style={{textAlign:"center"}}>
                <button type="submit" onClick={() => {
                    var o1 = document.getElementById("nhuj").value;
                    var o2 = document.getElementById("nuciu").value;
                    var o3 = document.getElementById("empl").value;
                    var o4 = document.getElementById("citye").value;
                    var o5 = document.getElementById("progyu").value;
                    if(o1 == "" || o2 == "" || o3 == "" || o4 == "" || o5 == ""){
                        alert("fill all the details");
                    }else{
                        var idrg = EntryId();
                        WriteSession(idrg,o1,o3,o2,o4,o5);
                        alert("Session Booked\nWe will call in upcoming hours\n\nThank you\nTeam AYuvi");
                        document.getElementById("nhuj").value = "";
                        document.getElementById("nuciu").value = "";
                        document.getElementById("empl").value = "";
                        document.getElementById("citye").value = "";
                        document.getElementById("progyu").value = "";

                    }
                }} class="btn btn-dark" style={{margin:"10px",border:"2px solid white",fontWeight:"bold",fontFamily:"Trebuchet MS', Lucida Sans Unicode, Lucida Grande, Lucida Sans, Arial, sans-serif"}}>Submit</button>
            </div>
            </div>
        </div>
        
    )
}

export default Session;
