import React,{useState , useEffect} from 'react';
import readEvents from '../firebase/firestore/upcoming';
import { Link } from "react-router-dom";
import "./progral.css";
function Event() {
    const [reviews, setReviews] = useState([]);
    useEffect(() => {
        const myFunction = async () => {
        const reviews = await readEvents();
            setReviews(reviews);
        };
        myFunction();
    }, []);
    return (
        <div style={{backgroundColor:"#1b1b1b"}}>
        {reviews.length > 0 &&
            reviews.map(
                (review) =>
                !(review.name.trim() === "" ||    review.des.trim() === "" || review.url.trim() === "") && (
                    <div className="card w-100" style={{marginBottom:"20px"}}>
                    <div className="card-body" style={{backgroundImage:"linear-gradient(#0100ff,#00ffa6)"}}>
                        <h5 className= "card-title" style={{color:"white"}}>{review.name}</h5>
                        <p className="card-text" style={{backgroundColor:"white",padding:"3px"}}>{review.des}</p>
                        <img src={review.url} class="img-fluid" alt="Responsive image" style={{height:"200px"}}/><br/>
                    </div>
                    </div>
                ))
        }
        </div>
    )
}

export default Event;
