import React from "react";
import Loader from "react-loader-spinner";

const LoaderComponent = () => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "100px",
      }}
    >
      <Loader type="Oval" color="#1b1b1b" />
    </div>
  );
};

export default LoaderComponent;
