import React from 'react'

function ErrorPageNotFound() {
    return (
        <div style={{textAlign :"center",margin:'200px'}}>
            <p style={{color:"red"}} className="h1">Error 404 Page Not Found</p>
            <p style={{color:"black"}} className="h3">Please try correct URL</p>
            <p style={{color:"black"}} className="h3">AYuvi</p>
        </div>
    )
}

export default ErrorPageNotFound;
