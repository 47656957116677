import React,{useState , useEffect} from 'react';
import readPrograms from '../firebase/firestore/ever';
import { Link } from "react-router-dom";
import "./progral.css";
function Program() {
    const [reviews, setReviews] = useState([]);
    useEffect(() => {
        const myFunction = async () => {
        const reviews = await readPrograms();
            setReviews(reviews);
        };
        myFunction();
    }, []);
    return (
        <div style={{backgroundColor:"#1b1b1b"}}>
        {reviews.length > 0 &&
            reviews.map(
                (review) =>
                !(review.name.trim() === "" || review.headurl.trim() === "" ||  review.des.trim() === "" || review.url.trim() === "") && (
                    <div className="card w-100" style={{marginBottom:"20px"}}>
                    <div className="card-body" style={{backgroundImage:"linear-gradient(#0100ff,#00ffa6)"}}>
                        <h5 className= "card-title" style={{color:"white"}}>{review.name}</h5>
                        <p className="card-text" style={{backgroundColor:"white",padding:"3px"}}>{review.des}</p>
                        <img src={review.headurl} class="img-fluid" alt="Responsive image" style={{height:"200px"}}/><br/>
                        <a style={{margin:"5px"}} href={review.url} className="btn btn-primary abcdf">Download Brochure</a>
                        <Link to="/booksession" style={{margin:"5px"}} className="btn btn-primary abcdf">Book Your First Trial session</Link>
                    </div>
                    </div>
                ))
        }
        </div>
    )
}

export default Program;
