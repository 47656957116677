import app from "../firebase";
import { getFirestore, addDoc, collection } from "firebase/firestore";
function WritePropost(id, nam,ag,emai, phon, cit,addr,listss,dat) {
  const firebase = getFirestore(app);
  try {
    const docRef = addDoc(collection(firebase, "order"), {
        OrderId:id,
        name: nam,
        age:ag,
        email:emai,
        phone:phon,
        city:cit,
        address:addr,
        item:listss,
        date:dat,
    });
  } catch (e) {
    console.error("Error adding document");
  }
}
export default WritePropost;
