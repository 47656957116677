import React from 'react'
import imageown from "./owner.png";
import "./home.css";
import {Link} from "react-router-dom";
function HomeIntro() {
    return (
        <div className="main-stack-information">
            <div className="msis">
                <div className="cfg">
                    <img src={imageown} alt="owner" className="owner-img bal-cont"/>
                </div>
                <div className="cfg cfd">
                    <p className="text-own bal-cont" style={{fontWeight:"bold"}} id="kji">AYuvi Health promotes natural healing disciplines and therapeutic techniques to achieve a state of good health. Health cannot be achieved by focusing only on the body. We have to work on Mind, body, and soul together to cherish the best gift given to us by God which is Health. HealthwithMindfulness is a program designed by Ms. Ashu Bhateja who is having an awesome experience of 10 years in teaching related to Dance, Fitness, Yoga, and meditation. She is a postgraduate in Public Relations also has relevant teaching experience in IB (PYP) and CBSE schools. She is REIKI Advance Healer at INFINITE LOVE ACADEMY. Naturopathy student and member of International Naturopathy Organisation (INO) India. AYuvi Health offers Fitness programs including Mindful sessions and REIKI sessions.</p>
                    <div className="Bysss">
                        <Link type="button" to="/booksession" class="btn btn-success" style={{marginBottom:"5px"}}>Book Your 1st Trial Session</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HomeIntro;
