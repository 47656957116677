import app from "../firebase";
import { getFirestore, addDoc, collection } from "firebase/firestore";
function WriteSession(id, n,ae,c,p,pro) {
  const firebase = getFirestore(app);
  try {
    const docRef = addDoc(collection(firebase, "session"), {
        sid :id,
        name: n,
        email:ae,
        phone:p,
        city:c,
        program:pro,
    });
  } catch (e) {
    console.error("Error adding document");
  }
}
export default WriteSession;