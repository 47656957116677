import "./review.css";
import React, { useEffect, useState } from "react";
import WriteData from "../firebase/database/reviewdata";
import WriteFire from "../firebase/firestore/reviewdata";
import EntryId from "../firebase/idgen";
import readReviewFireStore from "../firebase/firestore/readreview";
import LoaderComponent from "../../Loading";
const Review = () => {
  const [reviews, setReviews] = useState([]);
  useEffect(() => {
    const myFunction = async () => {
      const reviews = await readReviewFireStore();
      setReviews(reviews);
    };
    myFunction();
  }, []);

  return (
    <div className="body" style={{backgroundImage:"linear-gradient(#0100ff,#00ffa6)"}}>
      <div className="lagster">
        <div className="sublag">
          <p className="rev">Post your review</p>
          <input
            style={{width:"90%"}}
            type="text"
            className="inp"
            id="db1a"
            placeholder="Enter Name"
          />
          <br />
          <input
            style={{width:"90%"}}
            type="text"
            className="inp"
            id="db1b"
            placeholder="Enter Age"
          />
          <br />
          <input
            style={{width:"90%"}}
            type="text"
            className="inp"
            id="db1c"
            placeholder="Enter Phone number"
          />
          <br />
          <textarea
            style={{width:"90%"}}
            id="db1d"
            className="txtar"
            placeholder="Enter your review"
          ></textarea>
          <br />
          <button
            onClick={() => {
              var a = document.getElementById("db1a").value;
              var b = document.getElementById("db1b").value;
              var c = document.getElementById("db1c").value;
              var d = document.getElementById("db1d").value;
              if (a == "" || b == "" || c == "" || d == "") {
                alert("fill all the details");
              } else {
                var id = EntryId().toString();
                WriteData(id, a, b, c, d);
                WriteFire(id, a, b, c, d);
                alert("Thank you for your review please refresh your Page");
                document.getElementById("db1a").value = "";
                document.getElementById("db1b").value = "";
                document.getElementById("db1c").value = "";
                document.getElementById("db1d").value = "";
              }
            }}
            className="btnid"
            id="btnid"
          >
            SUBMIT
          </button>
        </div>
        <br />
        
      </div>
      <div className="review" id="add-db-rev">
        <p className="rev-i">REVIEWS BY OUR CUSTOMERS</p>
        {reviews.length === 0 && <LoaderComponent />}
        {reviews.error && <h2></h2>}
        {reviews.length > 0 &&
          reviews.map(
            (review) =>
              !(review.name.trim() === "" || review.review.trim() === "") && (
                <div className="hj" key={review.id}>
                  <p className="innerp">{review.name}</p>
                  <p className="rty">{review.review}</p>
                </div>
              )
          )}
      </div>
    </div>
  );
};
export default Review;
