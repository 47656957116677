import logo from './logo.svg';
import './App.css';
import { BrowserRouter , Route , Switch } from "react-router-dom";
import Order from "./pages/order";
import Review from "./pages/review/review";
import Session from "./pages/home/session";
import Product from "./pages/product";
import Navigation from './components/nav';
import Home from "./pages/home/home";
import SearchProduct from './pages/search';
import ErrorPageNotFound from "./pages/error";
import Program from "./pages/program/program";
import Events from "./pages/program/events";
import ConTact from './pages/review/contact';
function App() {
  return (
    <BrowserRouter>
    <Navigation/>
        <Switch>
          <Route exact path="/">
            <Home/>
          </Route>
          <Route path="/product">
            <Product/>
          </Route>
          <Route path="/review">
            <Review/>
          </Route>
          <Route path="/booksession">
            <Session/>
          </Route>
          <Route path="/order">
            <Order/>
          </Route>
          <Route path="/search">
            <SearchProduct/>
          </Route>
          <Route path="/programs">
            <Program/>
          </Route>
          <Route path="/events">
            <Events/>
          </Route>
          <Route path="/contact">
            <ConTact/>
          </Route>
          <Route>
            <ErrorPageNotFound/>
          </Route>
        </Switch>
    </BrowserRouter>
  );
}

export default App;
