import image1 from "./images/i1.jpg";
import image2 from "./images/i2.jpg";
import image4 from "./images/i4.jpg";
import image5 from "./images/i5.jpg";
import image8 from "./images/i8.jpg";
import image9 from "./images/i9.jpg";
import image10 from "./images/i10.jpg";
import image11 from "./images/i11.jpg";
import image12 from "./images/i12.jpg";
import image14 from "./images/i14.jpg";
import image19 from "./images/i19.jpg";
import image17 from "./images/i17.jpg";
import image20 from "./images/i20.jpg";
import image21 from "./images/i21.jpg";
const list = [
  { url: image21 },
  { url: image19 },
  { url: image20 },
  { url: image17 },
  { url: image1 },
  { url: image2 },
  { url: image4 },
  { url: image5 },
  { url: image8 },
  { url: image9 },
  { url: image10 },
  { url: image11 },
  { url: image12 },
  { url: image14 },
];

export default list;
