import React from 'react'

function SearchProduct() {
    return (
        <div>
            <form>
            <div class="form-group">
                <select type="email" class="form-control" style={{width:"90%",margin:"auto",marginTop:"20px"}} id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Select Your Product">
                    
                </select>
            </div>
            <div style={{textAlign:"center"}}>
                <button type="submit" class="btn btn-success">Submit</button>
            </div>
            </form>
        </div>
    )
}

export default SearchProduct;
