import React from 'react'
import slide from "./imageImports";
import { Carousel } from "react-bootstrap";
import "./delh.css";
function ImageSliderHome() {
    return (
        <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel" >
            <ol class="carousel-indicators">
                <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
                <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
                <li data-target="#carouselExampleIndicators" data-slide-to="3"></li>
                <li data-target="#carouselExampleIndicators" data-slide-to="4"></li>
                <li data-target="#carouselExampleIndicators" data-slide-to="5"></li>
                <li data-target="#carouselExampleIndicators" data-slide-to="6"></li>
                <li data-target="#carouselExampleIndicators" data-slide-to="7"></li>
                <li data-target="#carouselExampleIndicators" data-slide-to="8"></li>
                <li data-target="#carouselExampleIndicators" data-slide-to="9"></li>
                <li data-target="#carouselExampleIndicators" data-slide-to="10"></li>
                <li data-target="#carouselExampleIndicators" data-slide-to="11"></li>
                <li data-target="#carouselExampleIndicators" data-slide-to="12"></li>
                <li data-target="#carouselExampleIndicators" data-slide-to="13"></li>
            </ol>
            <div class="carousel-inner">
                <div class="carousel-item ghy active" >
                    <img class="d-block w-100 yui yui" src={slide[0].url} alt="First slide"/>
                </div>
                <div class="carousel-item ghy" >
                        <img class="d-block w-100 yui"  src={slide[1].url} alt="Second slide"/>
                </div>
                <div class="carousel-item ghy" >
                        <img class="d-block w-100 yui" src={slide[2].url} alt="Third slide"/>
                </div>
                <div class="carousel-item ghy" >
                        <img class="d-block w-100 yui" src={slide[3].url} alt="Second slide"/>
                </div>
                <div class="carousel-item ghy" >
                        <img class="d-block w-100 yui" src={slide[4].url} alt="Third slide"/>
                </div>
                <div class="carousel-item ghy" >
                        <img class="d-block w-100 yui" src={slide[5].url} alt="Second slide"/>
                </div>
                <div class="carousel-item ghy" >
                        <img class="d-block w-100 yui" src={slide[6].url} alt="Third slide"/>
                </div>
                <div class="carousel-item ghy" >
                        <img class="d-block w-100 yui" src={slide[7].url} alt="Second slide"/>
                </div>
                <div class="carousel-item ghy" >
                        <img class="d-block w-100 yui" src={slide[8].url} alt="Third slide"/>
                </div>
                <div class="carousel-item ghy" >
                        <img class="d-block w-100 yui" src={slide[9].url} alt="Second slide"/>
                </div>
                <div class="carousel-item ghy" >
                        <img class="d-block w-100 yui" src={slide[10].url} alt="Second slide"/>
                </div>
                <div class="carousel-item ghy" >
                        <img class="d-block w-100 yui" src={slide[11].url} alt="Second slide"/>
                </div>
                <div class="carousel-item ghy" >
                        <img class="d-block w-100 yui" src={slide[12].url} alt="Second slide"/>
                </div>
                <div class="carousel-item ghy" >
                        <img class="d-block w-100 yui" src={slide[13].url} alt="Second slide"/>
                </div>
            </div>
            <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="sr-only">Previous</span>
            </a>
            <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="sr-only">Next</span>
            </a>
        </div>
    );
}

export default ImageSliderHome;
